import React from 'react';

import { Parallax } from 'react-parallax';

const ParallaxSection = (props) => {
	// Props destructuring
	const { featuredData } = props;

	// Component Functions
	const title = featuredData.featuredTitle.split(' ');

	return (
		<Parallax
			bgImage={featuredData.featuredImageUrl}
			bgImageAlt='KEM Ecuador - Productos Destacados'
			strength={-200}
			style={{ width: '100%', height: '100%' }}
		>
			<div
				className='d-flex aligin-items-center justify-content-center'
				style={{
					height: '65vh',
					background:
						'radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 90%)',
				}}
			>
				<h1
					className='parallax-title d-flex align-items-center justify-content-center text-center'
					style={{ textTransform: 'uppercase' }}
				>
					{`${title[0]} ${title[1]}`}
					<br />
					{title[2]}
				</h1>
			</div>
		</Parallax>
	);
};

export default ParallaxSection;
