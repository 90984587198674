import React from 'react';

// Fontawesome Imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Fontawesome Imports
import {
	faFacebookF,
	faInstagram,
	faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';

// Styled Components Import
import styled from 'styled-components';

// Local imports
// import socialBtns from '../shared/SocialInfo';

// Styles Components
const HeroBackground = styled.div((props) => ({
	background: `url(${props.$imageUrl}) no-repeat`,
	backgroundSize: 'cover',
	backgroundPositionY: 'center',
	maxWidth: '100%',
	height: '550px',
	maxHeight: '550px',
	position: 'relative',

	'@media (max-width: 768px)': {
		backgroundSize: 'cover',
		backgroundPosition: '-350px, 0',
		maxWidth: '100%',
		height: '550px',
		maxHeight: '550px',
		position: 'relative',
	},
}));

const HomeHeroTransparentLayer = styled.div({
	position: 'absolute',
	top: '0',
	left: '0',
	width: '100%',
	height: '100%',
	background:
		'linear-gradient(90deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.65) 37.5%, rgba(255, 255, 255, 0.45) 47.5%, rgba(255, 255, 255, 0) 66.15%, rgba(255, 255, 255, 0) 100% )',

	'@media (max-width: 768px)': {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		background:
			'linear-gradient(120deg, rgba(255, 255, 255, 0.85) 15%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.3) 70%, rgba(255, 255, 255, 0) 100%)',
	},
});

const HeroText = styled.h1({
	fontSize: '30px',
	lineHeight: '35px',
	color: '#000',
	fontWeight: 'bold',
	maxWidth: '100%',

	'@media (min-width: 768px)': {
		textAlign: 'center',
	},
});

const HeroCtaBtn = styled.a`
	background-color: var(--primary);
	border-radius: 10px;
	border: none;
	padding: 7px 20px;
	color: #fff;
	text-decoration: none;
	text-align: center;
	font-weight: bold;
	text-transform: uppercase;
	width: 300px;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16), 0px 3px 6px rgba(0, 0, 0, 0.23);
	transition: 0.3s ease;

	&:hover {
		background-color: var(--secondary);
		transform: scale(1.02);
		color: #fff;
		text-decoration: none;
	}

	&:active {
		color: #fff;
		text-decoration: none;
	}
`;

const SocialBtn = styled.div`
	background-color: var(--secondary);
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	color: #fff;
	cursor: pointer;
	transition: 0.3s ease;

	&:hover {
		background-color: var(--primary);
		transform: scale(1.02);
	}
`;

const HomeHeroSection = (props) => {
	// Props destructuring
	const { heroData } = props;

	// Component Functions
	const socialBtns = [
		{ link: heroData.facebookLink, icon: faFacebookF },
		{ link: heroData.instagramLink, icon: faInstagram },
		{ link: heroData.whatsappLink, icon: faWhatsapp },
	];

	return (
		<HeroBackground $imageUrl={heroData.heroImageUrl}>
			<HomeHeroTransparentLayer>
				<div className='hero-cta-container d-flex flex-column align-items-center justify-content-center'>
					<HeroText className='px-4 px-md-3 ms-4 me-5 ms-lg-5 mt-5 mb-4'>
						{heroData.ctaText}
					</HeroText>
					<HeroCtaBtn
						className='my-3'
						href={heroData.whatsappLink}
						target='_blank'
						rel='noreferrer'
					>
						{heroData.ctaBtnText}
					</HeroCtaBtn>
					<div className='d-flex mt-4'>
						{socialBtns.map((btn) => (
							<a
								href={btn.link}
								key={btn.link}
								target='_blank'
								rel='noreferrer'
							>
								<SocialBtn className='mx-2'>
									<FontAwesomeIcon
										icon={btn.icon}
										style={{ fontSize: '2rem' }}
									/>
								</SocialBtn>
							</a>
						))}
					</div>
				</div>
			</HomeHeroTransparentLayer>
		</HeroBackground>
	);
};

export default HomeHeroSection;
