import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';

// Bootstrap Impoorts
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

// Fontawesome Imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const MenuBar = (props) => {
	// Props Destructuring
	const { auth, setSidebarStatus } = props;

	// Component Functions
	const menuOptions = [
		{ title: 'Inicio', link: '/inicio' },
		{ title: 'Nosotros', link: '/nosotros' },
		{ title: 'Catálogo', link: '/catalogo' },
	];

	return (
		<Navbar
			fixed='top'
			collapseOnSelect
			expand='lg'
			variant='dark'
			style={{
				filter: 'drop-shadow(-1px 2px 4px rgba(0,0,0,0.5))',
				padding: 0,
			}}
		>
			<div
				className='menu-shape'
				style={{
					width: '100%',
					backgroundColor: 'var(--primary)',
					height: 93,
				}}
			>
				<div
					style={{
						width: '100%',
						height: 56,
						position: 'absolute',
						top: 0,
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<Container>
						<div
							className='menu-toggle-btn'
							onClick={() => setSidebarStatus(true)}
						>
							<FontAwesomeIcon icon={faBars} style={{ fontSize: '1.3rem' }} />
						</div>
						<Navbar.Collapse id='responsive-navbar-nav'>
							<Nav className='menu-options-style'>
								{menuOptions.map((option, i) => (
									<LinkContainer
										to={option.link}
										key={option.title}
										className='custom-nav-link'
										activeClassName='active-nav-link'
									>
										<div>{option.title}</div>
									</LinkContainer>
								))}
							</Nav>
							{auth.uid ? (
								<Nav className='ms-auto'>
									<LinkContainer
										to='/mi-cuenta'
										className='custom-nav-link'
										activeClassName='active-nav-link'
									>
										<div>Administración</div>
									</LinkContainer>
								</Nav>
							) : (
								<Nav className='ms-auto'>
									<LinkContainer
										to='/ingresar'
										className='custom-nav-link'
										activeClassName='active-nav-link'
									>
										<div>Ingresar</div>
									</LinkContainer>
								</Nav>
							)}
						</Navbar.Collapse>
					</Container>
				</div>
			</div>
			<LinkContainer
				to='/inicio'
				className='brand-custom-class'
				style={{
					position: 'absolute',
					bottom: 0,
					marginLeft: -15,
					marginBottom: -15,
				}}
			>
				<Navbar.Brand className='d-flex'>
					<Image
						src='/img/logo-circular.png'
						alt='KEM Ecuador'
						style={{ width: 80, height: 80 }}
					/>
					<div className='pt-2 pt-md-1 ms-2 ms-md-3'>
						<div className='brand-title'>KEM CIA. LTDA.</div>
						<div style={{ fontSize: 12 }}>Aditivos para alimentos</div>
					</div>
				</Navbar.Brand>
			</LinkContainer>
		</Navbar>
	);
};

const mapStateToProps = (state) => {
	const { auth } = state.firebase;

	return {
		auth,
	};
};
export default connect(mapStateToProps)(MenuBar);
