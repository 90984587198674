import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';

const RouteWrapper = (props) => {
	// Props destructuring
	const { children } = props;

	// Component hooks
	const location = useLocation();
	const params = useParams();

	useLayoutEffect(() => {
		if (params.producto) {
			window.scrollTo(0, 0);
		}

		if (location.pathname === '/inicio') {
			window.scrollTo(0, 0);
		}

		if (location.pathname === '/nosotros') {
			window.scrollTo(0, 0);
		}
	}, [location.pathname, params.producto]);

	return <div>{children}</div>;
};

RouteWrapper.propTypes = {
	children: PropTypes.node.isRequired,
};

export default RouteWrapper;
