import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';

// Redux imports
import { connect } from 'react-redux';

// Bootstrap imports
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';

// FontAwesome Importss
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDirections } from '@fortawesome/free-solid-svg-icons';

// Styled Components Import
import styled from 'styled-components';

// Styled Components
const FooterBackground = styled.div((props) => ({
	backgroundImage: `url(${props.$imageUrl})`,
	backgroundPosition: '0 -250px',
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
	height: '50vh',
	marginTop: '2rem',

	'@media (max-width: 767px)': {
		backgroundPosition: '-50px -150px',
		backgroundSize: '1500px',
		marginTop: '1rem',
	},
}));

const FooterBackgroundOverlay = styled.div({
	width: '100%',
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'flex-end',
	paddingBottom: '2rem',
	background:
		'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0.6) 65%, rgba(0, 0, 0, 0.8) 70%, rgba(0, 0, 0, 0.95) 80%, rgba(0, 0, 0, 1) 100%)',

	'@media (max-width: 767px)': {
		paddingBottom: '1rem',
	},
});

const MapDirectionsBtn = styled.a`
	width: 100%;
	border-radius: 10px;
	background-color: #fff;
	border: 1px solid var(--secondary-accent);
	color: var(--secondary-accent);
	text-decoration: none;
	text-align: center;
	font-weight: 400;
	padding: 5px;
	cursor: pointer;
	box-shadow: 0px 2px 4px rgb(0 0 0 / 16%), 0px 2px 4px rgb(0 0 0 / 23%);
	transition: 0.3s ease;

	&:hover {
		background-color: var(--primary);
		box-shadow: 0px 4px 8px rgb(0 0 0 / 25%), 0px 4px 8px rgb(0 0 0 / 35%);
		color: #fff;
		text-decoration: none;
	}

	&:active {
		color: #fff;
		text-decoration: none;
	}

	@media (max-width: 320px) {
		font-size: 13px;
	}
`;

const Footer = (props) => {
	// Props destructuring
	const { webSite } = props;

	// Component functions
	const footerData = webSite[0]?.footer;

	return (
		<FooterBackground $imageUrl={footerData.imageUrl}>
			<FooterBackgroundOverlay>
				<Navbar.Brand className='d-flex mb-2 mb-lg-4'>
					<Image
						src={footerData.logo.logoUrl}
						alt='KEM Ecuador'
						style={{ width: 80, height: 80 }}
					/>
					<div className='pt-2 pt-md-1 ms-2 ms-md-3' style={{ color: '#fff' }}>
						<div className='brand-title'>{footerData.logo.logoTitle}</div>
						<div style={{ fontSize: 12 }}>{footerData.logo.logoSlogan}</div>
					</div>
				</Navbar.Brand>
				<div className='direcctions-container mb-2 mb=lg-4 pb-3 px-4 px-md-0'>
					<div className='directions'>
						<p className='direction mb-0' style={{ color: '#fff' }}>
							{footerData.ctaText}
						</p>
						<MapDirectionsBtn
							href={footerData.btnLink}
							target='_blank'
							rel='noreferrer'
						>
							{footerData.btnText}
							<FontAwesomeIcon icon={faDirections} className='ms-2' />
						</MapDirectionsBtn>
					</div>
				</div>
				<Container>
					<Row className='mx-0'>
						<Col
							xs={12}
							md={6}
							className='d-flex justify-content-center justify-content-md-end px-0'
						>
							<LinkContainer
								to={footerData.links[0].link}
								className='footer-custom-nav-link mb-3 mb-md-0'
							>
								<div>{footerData.links[0].title}</div>
							</LinkContainer>
							<LinkContainer
								to={footerData.links[1].link}
								className='footer-custom-nav-link mb-3 mb-md-0'
							>
								<div>{footerData.links[1].title}</div>
							</LinkContainer>
						</Col>
						<Col
							xs={12}
							md={6}
							className='d-flex justify-content-center justify-content-md-start px-0'
						>
							<LinkContainer
								to={footerData.links[2].link}
								className='footer-custom-nav-link mb-3 mb-md-0'
							>
								<div>{footerData.links[2].title}</div>
							</LinkContainer>
							<LinkContainer
								to={footerData.links[3].link}
								className='footer-custom-nav-link mb-3 mb-md-0'
							>
								<div>{footerData.links[3].title}</div>
							</LinkContainer>
						</Col>
					</Row>
				</Container>
			</FooterBackgroundOverlay>
		</FooterBackground>
	);
};

const mapStateToProps = (state) => {
	const { webSite } = state.firestore.ordered;

	return {
		webSite,
	};
};

export default connect(mapStateToProps)(Footer);
