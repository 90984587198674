import React from 'react';

// Bootstrap imports
import Image from 'react-bootstrap/Image';
import Spinner from 'react-bootstrap/Spinner';

// Styled components imports
import styled from 'styled-components';

// Styled components
const Wrapper = styled.div({
	display: 'flex',
	marginBottom: '1rem',
});

const Title = styled.h1({
	fontSize: '1rem',
	marginBottom: '0.25rem !important',
});

const LogoTextWrapper = styled.div({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	marginLeft: '1rem',
});

const PageLoading = () => {
	return (
		<div className='loading-container'>
			<Wrapper>
				<Image
					src='/img/logo-circular.png'
					alt='KEM Ecuador'
					style={{ width: 80, height: 80 }}
				/>
				<LogoTextWrapper>
					<Title>KEM CIA. LTDA.</Title>
					<div style={{ fontSize: 12 }}>Aditivos para alimentos</div>
				</LogoTextWrapper>
			</Wrapper>
			<Spinner
				animation='grow'
				className='mb-3'
				style={{ backgroundColor: 'var(--secondary-accent)' }}
			/>
			<p className='mb-0'>Cargando...</p>
		</div>
	);
};

export default PageLoading;
