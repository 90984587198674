import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';

// Bootstrap Imports
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

// Fontawesome Imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faBook,
	faHome,
	faTimes,
	faUsers,
} from '@fortawesome/free-solid-svg-icons';

const SideMenu = (props) => {
	// Props Destructuring
	const { setSidebarStatus } = props;

	// Component Functions
	const redirectLink = () => {
		setSidebarStatus(false);
	};

	const pages = [
		{ title: 'Inicio', route: '/inicio', icon: faHome },
		{ title: 'Nosotros', route: '/nosotros', icon: faUsers },
		{ title: 'Catálogo', route: '/catalogo', icon: faBook },
	];

	return (
		<div style={{ height: '100vh' }}>
			<Navbar
				className='header d-flex justify-content-between'
				variant='dark'
				style={{ zIndex: 5000, height: 65, backgroundColor: 'var(--primary)' }}
			>
				<Navbar.Brand
					href='#home'
					className='noselect ms-4'
					style={{ fontWeight: 500 }}
				>
					Menú principal
				</Navbar.Brand>
				<Nav>
					<Button
						className='d-flex'
						onClick={() => setSidebarStatus(false)}
						style={{
							backgroundColor: 'var(--secondary)',
							border: 'none',
							marginRight: '1rem',
						}}
					>
						<FontAwesomeIcon icon={faTimes} style={{ fontSize: '1rem' }} />
					</Button>
				</Nav>
			</Navbar>
			<div
				className='d-flex flex-column justify-content-between'
				style={{ height: 'calc(100% - 65px)' }}
			>
				<ListGroup
					style={{
						borderRadius: 0,
						borderLeft: 'none',
						borderRight: 'none',
					}}
				>
					{pages.map((page, i) => (
						<LinkContainer
							key={page.route}
							to={page.route}
							activeClassName='side-menu-option-active'
							onClick={redirectLink}
						>
							<div className='side-menu-option text-center item-list d-flex'>
								<div style={{ width: 35 }} className='me-2'>
									<FontAwesomeIcon
										icon={page.icon}
										style={{ fontSize: '1.3rem' }}
									/>
								</div>
								{page.title}
							</div>
						</LinkContainer>
					))}
				</ListGroup>
			</div>
		</div>
	);
};

export default SideMenu;
