import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import Router from './routes/Router';
import './App.css';
import './App-desktop.css';
import './App-mobile.css';
import MenuBar from './components/layout/MenuBar';
import SideMenu from './components/layout/SideMenu';
import PageLoading from './components/layout/PageLoading';

const App = (props) => {
	const { webSite } = props;
	const [sidebarStatus, setSidebarStatus] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (webSite === undefined) {
			setLoading(true);
		} else {
			setLoading(false);
		}
	}, [webSite]);

	const handleRefresh = async () => {
		if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
			const registration = await navigator.serviceWorker.getRegistration();
			if (registration && registration.waiting) {
				registration.waiting.postMessage({ type: 'SKIP_WAITING' });
			}

			localStorage.clear();

			if (caches && caches.keys) {
				const cacheNames = await caches.keys();
				await Promise.all(
					cacheNames.map(async (cacheName) => {
						await caches.delete(cacheName);
					})
				);

				window.location.reload(true);
			}
		}
	};

	useEffect(() => {
		const onSWUpdate = (registration) => {
			handleRefresh();
		};

		if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
			navigator.serviceWorker
				.register('/service-worker.js')
				.then((registration) => {
					registration.addEventListener('updatefound', () =>
						onSWUpdate(registration)
					);
				});

			const checkForUpdatesInterval = setInterval(() => {
				navigator.serviceWorker.getRegistration().then((registration) => {
					if (registration) {
						registration.update();
					}
				});
			}, 5 * 60 * 1000);

			return () => {
				clearInterval(checkForUpdatesInterval);
			};
		}
	}, []);

	return loading ? (
		<PageLoading />
	) : (
		<>
			<MenuBar setSidebarStatus={setSidebarStatus} />
			<SideMenu
				sidebarStatus={sidebarStatus}
				setSidebarStatus={setSidebarStatus}
			/>
			<div className='App'>
				<Router />
			</div>
		</>
	);
};

const mapStateToProps = (state) => {
	const { webSite } = state.firestore.ordered;
	return {
		webSite,
	};
};

export default compose(
	connect(mapStateToProps),
	firestoreConnect(() => [
		{
			collection: 'webSite',
		},
	])
)(App);
