/* eslint-disable consistent-return */
const initState = {
	adminProducts: [],
	adminProductsLimit: 12,
	productsLimit: 12,
};

const productsReducer = (state = initState, action) => {
	switch (action.type) {
		case 'FETCH_MORE_PRODUCTS':
			return {
				...state,
				productsLimit: state.productsLimit + 12,
			};
		case 'FETCH_MORE_ADMIN_PRODUCTS':
			return {
				...state,
				adminProductsLimit: state.adminProductsLimit + 12,
			};
		case 'SET_ADMIN_PRODUCTS':
			return {
				...state,
				adminProducts: action.payload,
				loading: false,
			};
		default:
			return state;
	}
};

export default productsReducer;