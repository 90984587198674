import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import TextTruncate from 'react-text-truncate';
import { useNavigate } from 'react-router-dom';

// Bootstrap Imports
import Card from 'react-bootstrap/Card';

// Styled Components Import
import styled from 'styled-components';

// Functios Imports
import { metricsClickSave } from '../../../functions/metricsSave';

// Local Imports
import RatingSingleStar from '../RatingSingleStar';
import { Button } from 'react-bootstrap';

// Styled Components
const GridItem = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const CustomCard = styled(Card)({
	width: '100%',
	height: '300px',
	minWidth: '250px',
	minHeight: '200px',
	textAlign: 'center',
	border: 'none !important',
	borderRadius: '20px !important',
	boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16), 0px 3px 6px rgba(0, 0, 0, 0.23)',

	'@media (max-width: 768px)': {
		borderTopLeftRadius: (props) =>
			props.$iscatalogue ? '15px !important' : '1.5rem !important',
		borderTopRightRadius: (props) =>
			props.$iscatalogue ? '15px !important' : '1.5rem !important',
		height: (props) => (props.$iscatalogue ? '220px' : '300px'),
		minWidth: 'auto',
		minHeight: 'auto',
	},
});

const ProductCalification = styled.div({
	width: '60px',
	height: '25px',
	backgroundColor: 'rgba(53, 53, 53, 1)',
	color: '#fff',
	fontWeight: '500',
	borderRadius: '0.5rem',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	position: 'absolute',
	top: '1rem',
	left: '1rem',

	'@media (max-width: 768px)': {
		width: (props) => (props.$iscatalogue ? '50px' : '55px'),
		height: (props) => (props.$iscatalogue ? '20px' : '20px'),
		top: (props) => (props.$iscatalogue ? '0.55rem' : '1rem'),
		left: (props) => (props.$iscatalogue ? '0.55rem' : '1rem'),
	},
});

const CustomCardImg = styled(Card.Img)({
	height: '150px',
	borderTopLeftRadius: '20px',
	borderTopRightRadius: '20px',
	objectFit: 'cover',

	'@media (max-width: 768px)': {
		borderTopLeftRadius: (props) => (props.$iscatalogue ? '1rem' : '1.5rem'),
		borderTopRightRadius: (props) => (props.$iscatalogue ? '1rem' : '1.5rem'),
		height: (props) =>
			props.$iscatalogue ? '100px !important' : '150px !important',
	},
});

const CustomCardBody = styled(Card.Body)({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',

	'@media (max-width: 768px)': {
		padding: '0.5rem !important',
	},
});

const TitleWrapper = styled.div({
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	padding: '0px 1rem',

	'@media (max-width: 768px)': {
		padding: (props) => (props.$iscatalogue ? '0px' : '0px 1rem'),
	},
});

const ProductTitle = styled(TextTruncate)({
	width: '100%',
	fontSize: '1.1rem',
	fontWeight: '600',
	color: 'var(--primary)',
	textTransform: 'uppercase',

	'@media (max-width: 768px)': {
		fontSize: (props) => (props.$iscatalogue ? '0.8rem' : '1.1rem'),
	},
});

const CustomCardFooter = styled(Card.Footer)({
	padding: '10px',
});

const CustomCardBtn = styled(Button)({
	width: '100%',
	borderRadius: '10px',
	border: '1px solid var(--primary)',
	color: 'var(--primary)',
	fontWeight: '500',
	fontSize: '0.9rem',

	'&:hover': {
		backgroundColor: 'var(--primary)',
		border: '1px solid var(--primary)',
		color: '#fff',
	},

	'&:focus': {
		backgroundColor: 'var(--primary) !important',
		border: '1px solid var(--primary) !important',
		color: '#fff',
	},

	'&:active': {
		backgroundColor: 'var(--primary) !important',
		border: '1px solid var(--primary) !important',
		color: '#fff',
	},
});

const ProductViewUi = (props) => {
	// Props Destructuring
	const {
		auth,
		product,
		profile,
		setModalProductImageUrl,
		setProductToShow,
		setShowProductModal,
	} = props;

	// Component Hooks
	const location = new useLocation();
	const navigate = new useNavigate();

	// Component functions
	const setProductData = () => {
		setModalProductImageUrl(product.productImage);
		setProductToShow(product);
		setShowProductModal(true);
		navigate(`/catalogo/${product.id}`);
		metricsClickSave({
			auth,
			metricType: 'ProductView',
			product,
			profile,
		});
	};

	return (
		<GridItem>
			<CustomCard
				$iscatalogue={location.pathname === '/catalogo' ? true : false}
			>
				{product.isFavorite && (
					<ProductCalification
						$iscatalogue={location.pathname === '/catalogo' ? true : false}
					>
						<RatingSingleStar background='dark' rating={5} />
					</ProductCalification>
				)}
				<CustomCardImg
					variant='top'
					src={product.productImage || '/img/product-placeholder.jpg'}
					$iscatalogue={location.pathname === '/catalogo' ? true : false}
				/>
				<CustomCardBody
					$iscatalogue={location.pathname === '/catalogo' ? true : false}
				>
					<TitleWrapper
						$iscatalogue={location.pathname === '/catalogo' ? true : false}
					>
						<ProductTitle
							containerClassName='truncated-text'
							line={2}
							truncateText='…'
							text={product.productName}
							$iscatalogue={location.pathname === '/catalogo' ? true : false}
						/>
					</TitleWrapper>
				</CustomCardBody>
				<CustomCardFooter>
					<CustomCardBtn
						variant='outline-primary'
						size='sm'
						onClick={setProductData}
					>
						Ver detalles
					</CustomCardBtn>
				</CustomCardFooter>
			</CustomCard>
		</GridItem>
	);
};

const mapStateToProps = (state) => {
	const { auth, profile } = state.firebase;

	return {
		auth,
		profile,
	};
};

export default connect(mapStateToProps)(ProductViewUi);
