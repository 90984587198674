import React from 'react';

// Bootstrap Imports
import Spinner from 'react-bootstrap/Spinner';

const LoadingList = (props) => {
	// Props Destructuring
	const { text } = props;

	return (
		<div className='d-flex flex-column align-items-center justify-content-center pt-3 pb-5'>
			<Spinner
				animation='grow'
				style={{ backgroundColor: 'var(--secondary-accent)' }}
			/>
			<p className='mt-3' style={{ color: 'var(--secondary-accent)' }}>
				{text}
			</p>
		</div>
	);
};

export default LoadingList;
