import React from 'react';

// FontAwesome Imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

// Styled Components Imports
import styled from 'styled-components';

// Styled Components
const RatingWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});

const CustomFontAwesomeIcon = styled(FontAwesomeIcon)({
	color: 'var(--bs-warning)',
	fontSize: 14,

	'@media (max-width: 768px)': {
		fontSize: 10,
	},
});

const RatingValue = styled.span({
	fontSize: 14,

	'@media (max-width: 768px)': {
		fontSize: 10,
	},
});

const RatingSingleStar = (props) => {
	// Props Destructuring
	const { rating } = props;

	return (
		<RatingWrapper>
			<CustomFontAwesomeIcon icon={faStar} className='me-1 me-lg-1' />
			<RatingValue>{rating.toFixed(2)}</RatingValue>
		</RatingWrapper>
	);
};

export default RatingSingleStar;
