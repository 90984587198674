import firebase from '../config/fbConfig';

export const metricsClickSave = (params) => {
	// Params Destructuring
	const { auth, metricType, product, profile } = params;

	if (!profile.isAdmin) {
		firebase
			.firestore()
			.collection('metrics')
			.add({
				metricType,
				productName: product.productName,
				productId: product.id || product.productId,
				isFavorite: product.isFavorite || false,
				userId: auth.uid || '',
				date: new Date(),
			})
			.then(() => {
				// console.log('Metrica guardada');
			})
			.catch((err) => {
				console.log(`Error: ${err}`);
			});
		// console.log(metricType);
	}
};
