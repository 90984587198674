import React from 'react';
import { connect } from 'react-redux';

// Bootstrap Imports
import Image from 'react-bootstrap/Image';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';

// UUID Import
import { v4 as uuidv4 } from 'uuid';

// FontAwesome Importss
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faDirections,
	faPhoneAlt,
	faTimes,
} from '@fortawesome/free-solid-svg-icons';
import {
	faFacebookF,
	faTelegramPlane,
	faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';

// Share Options Imports
import {
	FacebookShareButton,
	TelegramShareButton,
	WhatsappShareButton,
} from 'react-share';

// Styled Componets Imports
import styled from 'styled-components';

// Functios Imports
import { metricsClickSave } from '../../../functions/metricsSave';
import { Badge, Stack } from 'react-bootstrap';

// Styled Components
const ProductImageContainer = styled('div')({
	width: '100%',
	maxHeight: '200px',
	minHeight: '150px',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
});

const ProductImage = styled(Image)({
	width: '100%',
	maxWidth: '333.33px',
	maxHeight: '200px',
	borderRadius: '20px',
});

const PresentationContainer = styled('div')({
	marginLeft: '0.5rem',
});

const PresentationTitle = styled('div')({
	fontWeight: '500 !important',
});

const CustomBadge = styled(Badge)({
	backgroundColor: (props) =>
		props.$isParentCategory
			? 'var(--secondary-accent) !important'
			: 'var(--secondary) !important',
});

const CallBtn = styled.a`
	width: 100%;
	border-radius: 10px;
	background-color: #888888;
	border: none;
	color: #fff;
	text-decoration: none;
	text-align: center;
	font-weight: 400;
	padding: 5px;
	box-shadow: 0px 2px 4px rgb(0 0 0 / 16%), 0px 2px 4px rgb(0 0 0 / 23%);
	transition: 0.3s ease;

	&:hover {
		background-color: var(--secondary);
		box-shadow: 0px 4px 8px rgb(0 0 0 / 25%), 0px 4px 8px rgb(0 0 0 / 35%);
		color: #fff;
		text-decoration: none;
	}

	&:active {
		color: #fff;
		text-decoration: none;
	}

	@media (max-width: 320px) {
		font-size: 14px;
	}
`;

const OrderBtn = styled.a`
	width: 100%;
	border-radius: 10px;
	background-color: #ca303d;
	border: none;
	color: #fff;
	text-decoration: none;
	text-align: center;
	font-weight: 400;
	padding: 5px;
	box-shadow: 0px 2px 4px rgb(0 0 0 / 16%), 0px 2px 4px rgb(0 0 0 / 23%);
	transition: 0.3s ease;

	&:hover {
		background-color: var(--primary);
		box-shadow: 0px 4px 8px rgb(0 0 0 / 25%), 0px 4px 8px rgb(0 0 0 / 35%);
		color: #fff;
		text-decoration: none;
	}

	&:active {
		color: #fff;
		text-decoration: none;
	}

	@media (max-width: 320px) {
		font-size: 14px;
	}
`;

const MapDirectionsBtn = styled.a`
	width: 100%;
	border-radius: 10px;
	background-color: #fff;
	border: 1px solid var(--secondary-accent);
	color: var(--secondary-accent);
	text-decoration: none;
	text-align: center;
	font-weight: 400;
	padding: 5px;
	cursor: pointer;
	box-shadow: 0px 2px 4px rgb(0 0 0 / 16%), 0px 2px 4px rgb(0 0 0 / 23%);
	transition: 0.3s ease;

	&:hover {
		background-color: var(--primary);
		box-shadow: 0px 4px 8px rgb(0 0 0 / 25%), 0px 4px 8px rgb(0 0 0 / 35%);
		color: #fff;
		text-decoration: none;
	}

	&:active {
		color: #fff;
		text-decoration: none;
	}

	@media (max-width: 320px) {
		font-size: 13px;
	}
`;

const ProductModalViewUi = (props) => {
	// Props Destructuring
	const { auth, btnClose, product, productImageUrl, profile, show, webSite } =
		props;

	// Component Functions
	const productModalData = webSite[0]?.catalogue.productModal;

	return (
		<Modal
			show={show}
			onHide={btnClose}
			size='lg'
			aria-labelledby='product-view'
			centered
		>
			<Modal.Header className='custom-modal-header align-items-center'>
				<Modal.Title
					className='custom-modal-title text-truncate'
					id='product-view'
				>
					{product.productName}
				</Modal.Title>
				<div className='close-btn' onClick={btnClose}>
					<FontAwesomeIcon icon={faTimes} />
				</div>
			</Modal.Header>

			<Modal.Body className='d-flex flex-column align-items-center justify-contant-center py-3'>
				<div
					className='d-flex flex-column align-items-center justify-content-center'
					style={{ width: '100%' }}
				>
					<ProductImageContainer>
						<ProductImage
							src={productImageUrl || '/img/product-placeholder.jpg'}
						/>
					</ProductImageContainer>
				</div>
				<div className='product-section-container mt-1'>
					<p className='product-section-title text-center'>
						{productModalData.shareTitle}
					</p>
					<div className='d-flex justify-content-center my-2 my-md-2 pb-1'>
						<FacebookShareButton
							className='facebook-share-btn mx-2'
							url={`https://kemecuador.com/producto/${
								product.id || product.productId
							}`}
							quote={`Los mejores aditivos, conservantes y especias para la industria alimenticia los encuentras en @kemgrupoecuador como por ejemplo ${product.productName}`}
							hashtag={'#kemgrupoecuador'}
							onClick={() =>
								metricsClickSave({
									auth,
									metricType: 'FacebookShare',
									product,
									profile,
								})
							}
						>
							<FontAwesomeIcon className='share-btn-icon' icon={faFacebookF} />
						</FacebookShareButton>
						<TelegramShareButton
							className='telegram-share-btn mx-2'
							title={`Los mejores aditivos, conservantes y especias para la industria alimenticia los encuentras en @kemgrupoecuador como por ejemplo ${product.productName}`}
							url={`https://kemecuador.com/producto/${
								product.id || product.productId
							}`}
							onClick={() =>
								metricsClickSave({
									auth,
									metricType: 'TelegramShare',
									product,
									profile,
								})
							}
						>
							<FontAwesomeIcon
								className='share-btn-icon'
								icon={faTelegramPlane}
							/>
						</TelegramShareButton>
						<WhatsappShareButton
							className='whatsapp-share-btn mx-2'
							title={`Los mejores aditivos, conservantes y especias para la industria alimenticia los encuentras en @kemgrupoecuador como por ejemplo: ${product.productName}`}
							url={`https://kemecuador.com/catalogo/${
								product.id || product.productId
							}`}
							onClick={() =>
								metricsClickSave({
									auth,
									metricType: 'WhatsAppShare',
									product,
									profile,
								})
							}
						>
							<FontAwesomeIcon className='share-btn-icon' icon={faWhatsapp} />
						</WhatsappShareButton>
					</div>
				</div>
				<div className='product-view-scrollable-section'>
					{product.productDescription && (
						<div className='product-section-container mt-2'>
							<p className='product-section-title'>
								{productModalData.descriptionText}
							</p>
							<p className='mt-2 mb-2'>{product.productDescription}</p>
						</div>
					)}
					{product.productPresentation && (
						<PresentationContainer>
							<PresentationTitle className='mb-0'>
								{productModalData.presentationText}
							</PresentationTitle>
							<p className='mb-0'>{product.productPresentation}</p>
						</PresentationContainer>
					)}
					<div className='product-section-container mt-2'>
						<p className='product-section-title'>
							{productModalData.categoryText}
						</p>
						<Stack
							direction='horizontal'
							gap={2}
							className='pt-2 categories-scrollable-section'
						>
							<CustomBadge pill $isParentCategory>
								{product.productCategoryPrimary}
							</CustomBadge>
							{product?.productCategories &&
								product?.productCategories?.split(',').map((category) => (
									<CustomBadge pill key={uuidv4()}>
										{category}
									</CustomBadge>
								))}
						</Stack>
					</div>
					{(product.priceAlMenorVisible ||
						product.priceKgVisible ||
						product.priceSacoVisible) && (
						<div className='product-section-container mt-2 mt-md-3'>
							<p className='product-section-title mb-2'>
								{productModalData.prices.pricesTitle}
							</p>
							<ListGroup>
								{product.priceAlMenorVisible && (
									<ListGroup.Item className='d-flex justify-content-between'>
										<p className='mb-0'>{productModalData.prices.menor}</p>
										<p className='mb-0'>$ {product.productPriceAlMenor}</p>
									</ListGroup.Item>
								)}
								{product.priceKgVisible && (
									<ListGroup.Item className='d-flex justify-content-between'>
										<p className='mb-0'>{productModalData.prices.kilo}</p>
										<p className='mb-0'>$ {product.productPriceKg}</p>
									</ListGroup.Item>
								)}
								{product.priceSacoVisible && (
									<ListGroup.Item className='d-flex justify-content-between'>
										<p className='mb-0'>{productModalData.prices.bag}</p>
										<p className='mb-0'>$ {product.productPriceSaco}</p>
									</ListGroup.Item>
								)}
							</ListGroup>
						</div>
					)}
				</div>
				<div className='directions'>
					<p className='direction mb-0'>{productModalData.direction.ctaText}</p>
					<MapDirectionsBtn
						href='https://www.google.com.ec/maps/dir//KEM+Cia+Ltda,+gonzalo+correa+y,+N75,+Quito+170307/@-0.0974908,-78.4665866,15z/data=!4m8!4m7!1m0!1m5!1m1!1s0x91d58f8f04dd95e9:0xf8a3a932c2cb354!2m2!1d-78.4682762!2d-0.1002535'
						target='_blank'
						rel='noreferrer'
						onClick={() =>
							metricsClickSave({
								auth,
								metricType: 'MapDirections',
								product,
								profile,
							})
						}
					>
						{productModalData.direction.btnText}
						<FontAwesomeIcon icon={faDirections} className='ms-2' />
					</MapDirectionsBtn>
				</div>
			</Modal.Body>
			<Modal.Footer className='justify-content-between py-2'>
				<CallBtn
					href={productModalData.leftBtn.link}
					target='_blank'
					rel='noreferrer'
					onClick={() =>
						metricsClickSave({
							auth,
							metricType: 'CallButton',
							product,
							profile,
						})
					}
				>
					<FontAwesomeIcon icon={faPhoneAlt} className='me-2' />
					{productModalData.leftBtn.title}
				</CallBtn>
				<OrderBtn
					href={`https://api.whatsapp.com/send?phone=${
						productModalData.rightBtn.link
					}&text=Estimado%20grupo%20KEM%20Ecuador,%20me%20interesa%20su%20producto%20_*${
						product.productName
					}*_%20y%20quiero%20tener%20m%C3%A1s%20informaci%C3%B3n%20https://kemecuador.com/product/${
						product.id || product.productId
					}`}
					target='_blank'
					rel='noreferrer'
					onClick={() =>
						metricsClickSave({
							auth,
							metricType: 'WhatsAppContact',
							product,
							profile,
						})
					}
				>
					Hacer Pedido
					<FontAwesomeIcon icon={faWhatsapp} className='ms-2' />
				</OrderBtn>
			</Modal.Footer>
		</Modal>
	);
};

const mapStateToProps = (state) => {
	const { auth, profile } = state.firebase;
	const { webSite } = state.firestore.ordered;

	return { auth, profile, webSite };
};

export default connect(mapStateToProps)(ProductModalViewUi);
