import React, { useEffect, useState } from 'react';

// Boostrap Imports
import { LinkContainer } from 'react-router-bootstrap';
import Container from 'react-bootstrap/Container';

// Styled Components Import
import styled from 'styled-components';

// Firebase imports
import firebase from '../../config/fbConfig';

// Local Imports
import ProductModalViewUi from '../layout/products/ProductModalViewUi';
import ProductViewUi from '../layout/products/ProductViewUi';
import LoadingList from '../layout/LoadingList';

// Styled Components
const HorizontalScrollCards = styled.div({
	display: 'grid',
	gridGap: '1.5rem',
	overflowX: 'scroll',
	scrollSnapType: 'x proximity',
	justifyContent: (props) => (props.$gridCount <= 4 ? 'center' : 'flex-start'),
	gridTemplateColumns: (props) => `repeat(${props.$gridCount}, 250px)`,
	alignItems: 'center',
	maxWidth: '1140px',
	marginLeft: 'auto',
	marginRight: 'auto',
	padding: '0.5rem 1rem',

	'@media (max-width: 768px)': {
		gridGap: '2rem',
		gridTemplateColumns: (props) =>
			`repeat(${props.$gridCount}, minmax(250px, 1fr))`,
		justifyContent: (props) =>
			props.$gridCount === 1 ? 'center' : 'flex-start',
		marginLeft: 'none',
		marginRight: 'none',
	},

	':after': {
		content: '',
		width: '0.5rem',
		height: '1rem',
	},

	':-webkit-scrollbar': {
		display: 'none',
	},
});

const HeroCtaBtn = styled.button`
	background-color: var(--primary);
	border-radius: 10px;
	border: none;
	padding: 7px 20px;
	color: #fff;
	font-weight: bold;
	text-transform: uppercase;
	width: 300px;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16), 0px 3px 6px rgba(0, 0, 0, 0.23);
	transition: 0.3s ease;

	&:hover {
		background-color: var(--secondary);
		transform: scale(1.02);
	}
`;

const FeaturedProductsSection = (props) => {
	// Props Destructuring
	const { featuredData } = props;

	// Component State
	const [favoriteProducts, setFavoriteProducts] = useState([]);
	const [loadingProducts, setLoadingProducts] = useState(false);
	const [showProductMotal, setShowProductModal] = useState(false);
	const [productToShow, setProductToShow] = useState({});
	const [loadingImage, setLoadingImage] = useState(true);
	const [modalProductImageUrl, setModalProductImageUrl] = useState('');

	// Component Functions
	const resetProduct = () => {
		setProductToShow({});
		setLoadingImage(true);
		setModalProductImageUrl('');
		setShowProductModal(false);
	};

	useEffect(() => {
		setLoadingProducts(true);

		firebase
			.firestore()
			.collection('products-test')
			.where('productVisibility', '==', true)
			.where('isFavorite', '==', true)
			.get()
			.then((querySnapshot) => {
				let products = [];

				querySnapshot.forEach((doc) => {
					const data = doc.data();
					const id = doc.id;

					const product = {
						id,
						...data,
					};

					products.push(product);
				});

				setFavoriteProducts(products);
				setLoadingProducts(false);
			})
			.catch((err) => {
				console.log(err);
				setLoadingProducts(false);
			});
	}, []);

	return (
		<Container className='py-4'>
			{loadingProducts ? (
				<LoadingList text='Cargando productos' />
			) : (
				<HorizontalScrollCards
					className='no-scrollbar'
					$gridCount={favoriteProducts?.length}
				>
					{favoriteProducts &&
						favoriteProducts.map((product) => (
							<ProductViewUi
								key={product.id}
								product={product}
								setLoadingImage={setLoadingImage}
								setModalProductImageUrl={setModalProductImageUrl}
								setProductToShow={setProductToShow}
								setShowProductModal={setShowProductModal}
							/>
						))}
				</HorizontalScrollCards>
			)}
			<LinkContainer to={featuredData.btnLink}>
				<div
					className='d-flex justify-content-center mt-4 mt-md-5'
					style={{ width: '100%' }}
				>
					<HeroCtaBtn className='mt-2 mt-md-0'>
						{featuredData.btnText}
					</HeroCtaBtn>
				</div>
			</LinkContainer>
			<ProductModalViewUi
				btnClose={resetProduct}
				loadingImage={loadingImage}
				product={productToShow}
				productImageUrl={modalProductImageUrl}
				show={showProductMotal}
			/>
		</Container>
	);
};

export default FeaturedProductsSection;
