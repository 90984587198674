import React, { useRef, useState } from 'react';

// Bootstrap imports
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import ReactPlayer from 'react-player';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

// Styled Components Import
import styled from 'styled-components';

// Styled Components
const Title = styled.h1({
	fontSize: '2.5rem',
	fontWeight: 'bold',
});

const AtentionParagraph = styled.p({
	fontSize: '1.5rem',
	fontWeight: '500',
	textAlign: 'center',
	marginTop: '1rem',
});

const PlayerWrapper = styled.div({
	width: (props) => props.$with,
	height: (props) => props.$height,
	position: 'relative',
});

const PlayerOverlay = styled.div({
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	backgroundColor: (props) =>
		props.$isPlaying ? 'rgba(0, 0, 0, 0.0)' : 'rgba(0, 0, 0, 0.5)',
	transition: 'background-color 0.3s ease-in-out',
	cursor: 'pointer',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});

const PlayerButton = styled.div({
	width: '60px',
	height: '60px',
	borderRadius: '50%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundColor: 'rgba(255, 255, 255, 0.5)',
	visibility: (props) => (props.$isPlaying ? 'hidden' : 'visible'),
});

const AtentionSection = (props) => {
	// Props destructuring
	const { atentionData } = props;

	// Component state
	const [isPlaying, setIsPlaying] = useState(false);

	// Component hooks
	const playerRef = useRef(null);

	const handlePlay = () => {
		const player = playerRef.current.getInternalPlayer();

		if (player) {
			if (player.paused) {
				player.play();
				setIsPlaying(true);
			} else {
				player.pause();
				setIsPlaying(false);
			}
		}
	};

	return (
		<Container
			className='d-flex flex-column align-items-center justify-content-center py-4 py-md-0'
			style={{ minHeight: '80vh', color: 'var(--secondary)' }}
		>
			<Title className='mt-4'>{atentionData.atentionTitle}</Title>
			<Row
				className='d-flex align-items-center py-4 mx-0 mt-lg-3 mb-lg-5'
				style={{ width: '100%' }}
			>
				<Col
					xs={{ span: 12, order: 2 }}
					md={{ span: 6, order: 1 }}
					className='d-flex justify-content-end'
				>
					<div className='me-lg-5 mt-4 mt-md-0' style={{ maxWidth: 450 }}>
						<div className='d-flex justify-content-center align-items-center'>
							{atentionData.associationLogos.map((logo) => (
								<Image
									key={logo}
									src={logo}
									alt={logo}
									className='m-3'
									style={{ height: '100%', maxHeight: '100px' }}
								/>
							))}
						</div>
						<AtentionParagraph>{atentionData.paragraph}</AtentionParagraph>
					</div>
				</Col>
				<Col
					xs={{ span: 12, order: 1 }}
					md={{ span: 6, order: 2 }}
					className='d-flex align-items-center justify-content-center justify-content-md-start ps-0 ps-md-5 pe-0 pb-4 py-md-0'
				>
					<PlayerWrapper
						$with={atentionData.atentionMedia.width}
						$height={atentionData.atentionMedia.height}
					>
						<ReactPlayer
							ref={playerRef}
							id='react-player'
							url={atentionData.atentionMedia.url}
							width={atentionData.atentionMedia.width}
							height={atentionData.atentionMedia.height}
						/>
						<PlayerOverlay $isPlaying={isPlaying} onClick={handlePlay}>
							<PlayerButton $isPlaying={isPlaying}>
								<FontAwesomeIcon
									icon={faPlay}
									style={{ fontSize: '1.5rem' }}
									className='ms-1'
								/>
							</PlayerButton>
						</PlayerOverlay>
					</PlayerWrapper>
				</Col>
			</Row>
		</Container>
	);
};

export default AtentionSection;
