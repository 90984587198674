import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import loadable from '@loadable/component';

// Local Imports
import RouterWrapper from './RouterWrapper';

// Pages Imports
import Home from '../pages/Home';
import PageLoading from '../components/layout/PageLoading';

// Lazy Imports
const AboutUs = loadable(() => import('../pages/AboutUs'));
const Catalogue = loadable(() => import('../pages/Catalogue'));
const Login = loadable(() => import('../pages/Login'));
const MyAccount = loadable(() => import('../pages/MyAccount'));
const NotActiveUser = loadable(() => import('../pages/NotActiveUser'));

const Router = () => {
	return (
		<RouterWrapper>
			<Suspense fallback={<PageLoading />}>
				<Routes>
					<Route path='/inicio' element={<Home />} />
					<Route exact path='/' element={<Navigate replace to='/inicio' />} />
					<Route path='/nosotros' element={<AboutUs />} />
					<Route path='/catalogo/:producto?' element={<Catalogue />} />
					<Route path='/ingresar' element={<Login />} />
					<Route path='/mi-cuenta' element={<MyAccount />} />
					<Route path='/usuario-no-activo' element={<NotActiveUser />} />
				</Routes>
			</Suspense>
		</RouterWrapper>
	);
};

export default Router;
