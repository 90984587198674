import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// Redux Imports
import { configureStore } from '@reduxjs/toolkit';
import { Provider, useSelector } from 'react-redux';

// Redux persist
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';

// Firebase Redux Imports
import { createFirestoreInstance, getFirestore } from 'redux-firestore';
import {
	ReactReduxFirebaseProvider,
	getFirebase,
	isLoaded,
} from 'react-redux-firebase';

// Router Imports
import { createBrowserHistory } from 'history';
import { updateLocation } from './location';

// Reducer Imports
import rootReducer from './store/reducers/rootReducer';

// App config Imports
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

// Firebase Imports
import fbConfig from './config/fbConfig';

// CSS Imports
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';
import PageLoading from './components/layout/PageLoading';

// Persist config and black-list
const persistConfig = {
	key: 'root',
	storage,
	blacklist: ['auth', 'firestore', 'products'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Store configuration
const store = configureStore({
	reducer: persistedReducer,
	devTools: process.env.NODE_ENV !== 'production',
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			thunk: {
				extraArgument: { getFirebase, getFirestore },
			},
			serializableCheck: false,
		}),
});

// Update location
const history = createBrowserHistory();

store.unsubscribeHistory = history.listen(updateLocation(store));

// Persistor
const persistor = persistStore(store);

// Firebase redux config
const rrfConfig = {
	useFirestoreForProfile: true,
	userProfile: 'users',
	attachAuthIsReady: true,
};

const rrfProps = {
	firebase: fbConfig,
	config: rrfConfig,
	dispatch: store.dispatch,
	createFirestoreInstance,
};

const root = ReactDOM.createRoot(document.getElementById('root'));

function AuthIsLoaded({ children }) {
	const auth = useSelector((state) => state.firebase.auth);
	if (!isLoaded(auth)) return <PageLoading />;
	return children;
}

AuthIsLoaded.propTypes = {
	children: PropTypes.node.isRequired,
};

// store.firebaseAuthIsReady.then(() => {
root.render(
	<BrowserRouter>
		<Provider store={store}>
			<ReactReduxFirebaseProvider {...rrfProps}>
				<PersistGate loading={null} persistor={persistor}>
					<AuthIsLoaded>
						<App />
					</AuthIsLoaded>
				</PersistGate>
			</ReactReduxFirebaseProvider>
		</Provider>
	</BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
