/* eslint-disable consistent-return */
const initState = {
	signUpAuthError: null,
	signInAuthError: null,
	signInUserError: null,
	loading: false,
	toastMsg: '',
};

const authReducer = (state = initState, action) => {
	switch (action.type) {
		case 'AUTH_START':
			return {
				...state,
				loading: true,
			};
		case 'LOGIN_ERROR':
			if (action.err.code === 'auth/user-not-found') {
				return {
					...state,
					signInUserError: 'Usuario no registrado',
					loading: false,
				};
			} else if (action.err.code === 'auth/wrong-password') {
				return {
					...state,
					signInAuthError: 'Credenciales no válidas',
					loading: false,
				};
			}
			break;
		case 'LOGIN_SUCCESS':
			return {
				...state,
				signInAuthError: null,
				loading: false,
			};
		case 'SIGNOUT_SUCCESS':
			return state;
		case 'SIGNUP_SUCCESS':
			return {
				...state,
				signUpAuthError: null,
				loading: false,
				toastMsg: 'Usuario creado',
			};
		case 'SIGNUP_ERROR':
			if (action.err.code === 'auth/email-already-in-use') {
				return {
					...state,
					signUpAuthError: 'Ya existe un usuario registrado con éste email.',
					loading: false,
				};
			}
			break;
		case 'USER_UPDATE_START':
			return {
				...state,
				loading: true,
			};
		case 'USER_UPDATE_SUCCESS':
			return {
				...state,
				toastMsg: 'Usuario actualizado',
				loading: false,
			};
		case 'USER_UPDATE_MSG_RESET':
			return {
				...state,
				toastMsg: '',
			};
		case 'USER_UPDATE_ERROR':
			return {
				...state,
				toastMsg: 'Error actualizando',
				loading: false,
			};
		default:
			return state;
	}
};

export default authReducer;
