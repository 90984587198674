import React from 'react';

// Redux imports
import { connect } from 'react-redux';

// Local Imports
import AtentionSection from '../components/home/AtentionSection';
import FeaturedProductsSection from '../components/home/FeaturedProductsSection';
import Footer from '../components/layout/Footer';
import HomeHeroSection from '../components/home/HomeHeroSection';
import ParallaxSection from '../components/home/ParallaxSection';

const Home = (props) => {
	// Props destructuring
	const { webSite } = props;

	// Component functions
	const heroData = webSite[0]?.home.heroSection;
	const atentionData = webSite[0]?.home.atentionSection;
	const featuredData = webSite[0]?.home.featuredProducts;

	return (
		<>
			<HomeHeroSection heroData={heroData} />
			<AtentionSection atentionData={atentionData} />
			<ParallaxSection featuredData={featuredData} />
			<FeaturedProductsSection featuredData={featuredData} />
			<Footer />
		</>
	);
};

const mapStateToProps = (state) => {
	const { webSite } = state.firestore.ordered;

	return {
		webSite,
	};
};

export default connect(mapStateToProps)(Home);
